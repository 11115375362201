@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;

.login-powertrain {

	.login-container {
		.error-message {
			color: $red;
			font-weight: bold;
		}

		.not-production-warning-container {
            @include font-medium-text-md;
			display: flex;
    		align-items: center;
    		line-height: 16px;
    		column-gap: 16px;
			margin-top: 47px;

			.not-production-warning {
				font-size: 14px;
				color: $dodger-blue;
				text-decoration: none;

				&:hover {
					color: $dodger-blue;
				}
				&:active {
					color: $dodger-blue;
				}
			}
		}
	}

}

.cookie-consent-modal.modal {
	#cookie-consent-modal {
		top: 10%;
		width: 650px;
		.cookie-consent-content {
			margin-top: 15px;
			margin-left: 20px;

			.learn-more-link {
				color: $dodger-blue;
				text-decoration: underline;
			}
		}
		.general-button {
			margin-top: 39px;
			padding: 0px 20px;
			height: 40px;;
			width: 155px;
			font-weight: 400;
			.button-text {
				@include font-medium-text-md;
				line-height: 40px;
			}
		}
	}
}

.disabled-authentication-wrapper {
	display: flex;
	background-color: $black-transparent;
	opacity: 0.38;
	align-items: center;
	justify-content: center;
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0px;
	left: 0px;
}
