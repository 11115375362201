@use "~theme/colors" as *;
@use "~theme/breakpoints" as *;
@use "~theme/mixins" as *;
@use '~theme/typography' as *;


#conditional-insights-parameters-container {
    padding: 12px 0 12px;
    height: 100%;

    .conditional-insights-drive-status-component {
        margin: 12px;
    }

    .conditional-insight-status-message {
        font-family: $font-family-light;
        font-size: 14px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        color: #696969;
        text-align: center;
    }

    .conditional-kpi-section-chart-list {
        display: grid;
        grid-template-columns: 1fr;
        margin: 12px;
        /* Two column layout */
        gap: 20px;
        overflow-x: auto;
        width: 100%;

        .kpi_status_title {
            @include unselectable;
            @include font-medium-text-md;

            span:first-of-type {
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }

    .conditional-kpi-section-chart-item {
        padding: 20px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        /* Additional styling for chart items */
    }

    .conditional-kpi-section-chart-item:has(.popout-chart-row--condition-insights) {
        padding: 0 20px 20px 20px;
    }

    /* Media query for smaller screens */
    @media screen and (max-width: 768px) {
        .conditional-kpi-section-chart-list {
            grid-template-columns: 1fr;
            /* One column layout */
        }
    }
}