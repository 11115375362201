@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;
@use "~theme/breakpoints" as *;

#bulk-file-section {
	margin-bottom: 16px;

	.section-heading {
		height: 32px;
		margin-bottom: 3px;
		.bulk-data-upload-container {
			align-self: flex-end;

			.section-label {
				@include font-regular-text-md;
				color: $dove-gray;
			}
		}

		> .section-body {
			display: flex;
			justify-content: flex-end;
			white-space: pre-wrap;

			> .select-bulk-upload-input {
				display: none;
			}

			> .select-bulk-upload {
				color: $dodger-blue;
				text-decoration: underline;
				text-underline-offset: 1px;
				cursor: pointer;

				&.disabled {
					cursor: default;
				}
			}
		}
	}

	.dropzone-container {
		@include font-regular-text-md;
		@include create-border($silver-chalice, 2px, 1px, dashed);
		width: 100%;
		max-height: 120px;
		height: 100%;
		overflow: auto;
		padding: 8px 16px;
		box-sizing: border-box;
		background-color: $wild-sand;
		outline: none;
		transition: border 0.24s ease-in-out;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: $mine-shaft-transparent;
		position: relative;

		@include md-max-h {
			max-height: 110px;
		}

		> .dropzone-file {
			height: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;

			> .bulk-upload-selected-file {
				margin-bottom: 12px;
				color: $nero;
				font-size: 14px;
				line-height: 19px;
				display: flex;
				align-items: center;

				svg {
					margin-right: 8px;
				}

				> .bulk-upload-selected-file-delete {
					margin-left: 11px;
					cursor: pointer;

					&.disabled {
						cursor: default;
					}
				}
			}
		}

		> .dropzone-no-file {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.upload-label {
				width: 400px;
				font-size: 12px;
				text-align: center;
			}
		}
	}

	.dropzone-row {
		height: 120px;
	}

	.dropzone-scrollbar {
		overflow-y: scroll;
	}

	.dropzone-scrollbar-primary::-webkit-scrollbar {
		width: 5px;
		background-color: $wild-sand;
	}

	.dropzone-scrollbar-primary::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 6px $black-transparent;
		-webkit-box-shadow: inset 0 0 6px $black-transparent;
		background-color: $alto;
	}

	.dropzone-scrollbar-primary {
		scrollbar-color: $alto $wild-sand;
		scrollbar-width: thin;
	}
}
