@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use "~theme/table" as *;

.tools_powertrain-subscription_status {
    @include general-table;
    padding: 21px 24px 32px 0px;
    height: 100%;
    overflow-y: scroll;

    .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 24px;
    }

    .filter_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        .filter_section_right {
            display: flex;
            gap: 16px;
            align-items: center;
            .total_filter_items {
                font-size: 12px;
                color: $dove-gray;
            }
        }
    }

    .advance_filters {
        background-color: $wild-sand;
        padding: 16px;
        display: flex;
        gap: 16px;
        margin-bottom: 16px;
        justify-content: space-between;
        align-items: flex-end;

        &__expiration, &__assetType {
            width: 260px;
            .general-select__control {
                height: 36px
            }
        }

        &__left {
            display: flex;
            gap: 16px;
        }
        &__subscriptiontype {
            font-family: "ABBvoice-Regular";
            font-weight: lighter;
        }
    }

    label{
        color: $dove-gray;
        font-size: 14px;
        padding-bottom: 4px;
        font-family: 'ABBvoice-Regular'
    }
}
