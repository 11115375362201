@use "~theme/colors" as *;
@use "~theme/mixins" as *;

.pt-detailed-information-container {
	@include standard-tabContent-layout;

	.details-wrapper {
		width: 100%;
		.notification-row {
			margin: 0;

			.col {
				padding: 0;
				position: relative;

				.notification-status-bar {
					display: flex;
					width: 100%;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					line-height: 40px;
					height: auto;
				}
			}
		}

		.detailed-information-content {
			@include create-border;
			overflow-y: auto;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;

			.tab-container {
				background-color: $wild-sand;
				padding-bottom: 0;
				display: flex;
				flex-direction: column;
				height: 100%;
				.tab-content {
					height: 100%;
					overflow-y: unset;
				}

                .tab {
                    word-break: break-word;
                }

				.tab:first-of-type.selected {
					border-left: none;
    				padding-left: 18px;
				}
			}

			.pt-asset-permission-error {
				height: 250px;
				padding: 15px;
			}
		}
	}
}
