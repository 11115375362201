@use '~theme/colors' as *;
@use '~theme/typography' as *;

.error-page-container {
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.error-content {
		.row {
			.title {
				@include font-bold-title-lg;
				line-height: 37px;
				padding-bottom: 14px;

				.red-line {
					color: $red;
					width: 30px;
					height: 5px;
					background: $red;
					margin-bottom: 10px;
				}
			}

			.status-code {
				@include font-regular-text-md;
				color: $mine-shaft;
				line-height: 16px;

				span {
					&:last-of-type {
						font-weight: bold;
					}
				}
			}

			.details {
				@include font-regular-text-lg;
				padding-top: 40px;
				line-height: 20px;
				color: $black
			}

			.button-area {
				padding-top: 80px;

				.general-button {
					height: 40px;

					.button-text {
						line-height: 40px;
					}
				}

				.logout-text {
					padding-left: 30px;
					color: $blue-ribbon;
					cursor: pointer;
				}
			}
		}
	}
}
