@use '~theme/colors' as *;
@use '~theme/typography' as *;

.changed-parameter-list-modal.modal {
	.general-dialog {
		position: relative;
		width: 960px;

		.modal-header {
			padding: 10px 25px;
		}

		.modal-content {
			min-height: 600px;
		}
	}

	.changed-parameter-list-form {
		@include font-regular-text-md;
		line-height: 16px;

		.container {
			width: auto;
		}

		.changed-parameter-list-header {
			display: flex;
			align-items: center;
			height: 30px;
			margin-top: 5px;
			padding-right: 20px;
			border-bottom: 1px solid $black;
		}

		.time-selector-row .time-selector-row-content {
			justify-content: flex-start;
			padding: 20px 15px;
		}

		.change-parameter-table {
			.rc-table-tbody {
				.rc-table-row {
					.rc-table-cell {
						padding-top: 6px;
						padding-bottom: 6px;
					}
				}
			}
		}
	}
}
