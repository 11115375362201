@use "~theme/breakpoints" as *;

#vibration-fft-container {
    #popout-chart-row-button-container{
        .left-button-group{
            .general-select-container {
                max-width: 10rem;
            }
        }
    }
}
