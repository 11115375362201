@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

#trend-container .measurement-list-container {
    
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin: 0px;
    .signal-block {
        position: relative;
    }
    .measurement-list {
        @include create-border;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 16px;
        margin: 6px 0;

        .measurement-list-title {
            @include font-medium-text-lg;
            color: $nero;
            line-height: 24px;
            padding-left: 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid $nero;
            display: flex;
            justify-content: space-between;

            svg {
                margin: 0 10px 0 10px;
                cursor: pointer;
            }
        }
        .measurement-list-buttons {
            display: flex;
        }
        .signal-group-header-info {
            display: flex;
            color: $dove-gray;
            font-size: 13px;
            span {
                padding-right: 10px;
                padding-top: 5px;
            }
        }
        .measurement-list-body {
            overflow: auto;
            position: relative;
            max-height: 210px;
        }
        .measurement-list-item {
            @include font-regular-text-md;
            @include shadow-inset-alto;
            display: flex;
            align-items: center;
            padding: 12px 8px;
            line-height: 18px;
            
            &:hover {
                background-color: $concrete;
                cursor: pointer;
            }

            &.selected-measurement-list-item {
                background-color: $light-blue;
            }
            .data-command-checkbox{
                width: 8%;
                svg{
                    padding: 0px,16px,0px,16px;
                }
            }

            .data-label {
                display: flex;
                width: 45%;

                .health-icon {
                    align-self: center;

                    svg {
                        margin: 0 8px 0 8px;
                    }
                }
            }

            .data-value {
                overflow-wrap: break-word;
                width: 25%;
            }
            .data-command {
                text-align: right;
                width: 30%;

                >span {
                    margin: 0 10px 0 10px;
                }

                svg {
                    cursor: pointer;
                }

                .tippy-popper {
                    text-align: left;
                }
            }
            // .d-none class is used to hide the element, needs to be removed after PT-23 release
            .d-none {
                display: none;
            }
        }
    }

    @include customScreenMaxWidth(1440) {
        width: 100%;
        .measurement-list {
            width: 100%;
            height: initial;
        }
    }

    @include customScreenMaxWidth(1390) {
        .measurement-list {
            flex-basis: 100%;
        }
    }

    @include customScreenMaxWidth(1460) {
        .measurement-list {
            .measurement-list-item {
                .data-label {
                    width: 40%;
                }

                .data-value {
                    width: 25%;
                }

                .data-command {
                    width: 35%;
                }
            }
        }
    }
}
