@use '~theme/colors' as *;
@use '~theme/mixins' as *;

.condition-monitoring-asset-list{
    padding: 0px;
    margin: 0px;
    display:grid; 
    gap: 0px 24px;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr) );
    align-content: start;

    .no-information{
        @include shadow-inset-alto;
        grid-column: -1/1;
        width: 100%;
        text-align: center;
        padding: 8px;
        color: rgba($nero , 0.3);
    }

    .condition-monitoring-asset{
        @include shadow-inset-alto;
        display: flex;
        cursor: pointer;
        padding: 8px;
    
        &__icon{
            display: flex;
            align-items: center;
        }
    
        &__name{
            flex: 1;
            display: flex;
            align-items: center;
            padding-left: 8px;
            color: $nero;
            line-height: 16px;
            word-break: break-word;
        }
        &__condition-icons{
            display: flex;
        }
    
        &:hover{
            background-color: $gallery;
        }
    }
}
