@use '~theme/typography'as *;
@use '~theme/_colors'as *;


.edit-muted-asset-container {
    margin-left: 25px;

    .edit-muted-asset-description {
        @include font-regular-text-md;
    }

    .notification-group-selection,
    .period-selection {
        @include font-regular-text-md;

        .section-title {
            margin-top: 20px;
            margin-bottom: 10px;
            @include font-bold-text-md;
            display: flex;

            .title-text {
                margin-left: 10px;
            }
        }
    }

    .notification-group-selection {
        .notification-groups-list {
            display: flex;

            .column {
                min-width: 150px;
                max-width: 250px;

                .mute-notification-group {
                    display: flex;

                    .custom-checkbox {
                        margin-left: 25px;
                    }

                    .group-name {
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .period-selection {
        .mute-period {
            margin-left: 25px;
            margin-bottom: 15px;

            .time-selector-row-content {
                justify-content: flex-start;
            }
        }
    }
}