@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;

#general-filter-sidebar {
	@include font-regular-text-md;
	position: fixed;
	width: 165px;
	height: 24px;
	top: 235px;
	display: flex;

	/* UI/Blue-60 */
	background: $dodger-blue;
	box-shadow: 0px 8px 16px $black-transparent;
	border-radius: 0px 0px 4px 4px;
	transform: rotate(270deg);
	transform-origin: top left;
	color: $white;
	z-index: $sidebar-zIndex;

	.icon-collapsed {
		transform: rotate(90deg);
		float: right;
		margin-right: 10px;
	}

	.filter-text {
		margin-left: 14px;
		margin-right: auto;
	}

	&:hover {
		cursor: pointer;
	}
}
 .footer_container{
	display: flex;
	justify-content: space-between;
 }
