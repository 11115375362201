@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;
@use '~theme/zIndexes' as *;

.overview-card{
    @include create-border;
    position: relative;

    &.opened{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: none;
    }
    &.faded{
        opacity: 0.25;
    }

    &::after{
        position: absolute;
        content: "";
        width:calc(100% + 2px);
        top: -1px;
        left: -1px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height:6px;
        border-top-style: solid;
        border-top-width: 6px;
    }
    &.poor::after {
        border-color: $pomegranate;
    }
    &.tolerable::after{
        border-color: $orange-peel
    }
    &.good::after{
        border-color: $jungle-green;
    }
    &.unknown::after{
        border-color: $silver;
    }
    &.not-applicable::after{
        border-color: $nero;
    }

    &__header{
        margin-top: 5px;
        padding: 12px 12px 8px 16px;
        width:100%;
        display: flex;
		flex-direction: row;
        justify-content: space-between;

        &__text {
            @include font-regular-text-sm;
            line-height: 24px;
            color: $dove-gray;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__icons{
            display: flex;

            .context-menu{
                margin-left: 8px;
            }
        }

    }

    &__body{
        padding: 0px 16px 18px 16px;
        line-height: 16px;
        @include font-medium-text-md;
        color: $mine-shaft;
        div {
            max-width: 100%;
            width:fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}


.condition-icon{
    margin: 0 5px;
    display: flex;

    &__row{
        display: flex;
        align-items: center;
    }

}


.overview-card{

    &__dropdown{
        @include create-border($radius: 0px);
        position: absolute;
        border-bottom: none;
        height: 17px;
        width: calc(100% + 2px);
        background-color: $white;
        left: -1px;

        &__icon{
            display: flex;
            justify-content: center;
            padding-top: 12px;
            background-color: $white;
            position: relative;
            z-index: $dropdown-icon-zIndex;
        }

       &::after{
        position: absolute;
        content: "";
        width: calc(100% + 2px);
        top: 15px;
        left: -1px;
        height: 5px;
        border-left: 1px solid $alto;
        border-right: 1px solid $alto;
        }
    }
    &__details{
        @include create-border;
        position:relative;
        padding: 16px;
        &::after{
            position: absolute;
            content: "";
            width: calc(100% - 8px);
            top: 0px;
            left: 4px;
            height: 7px;
            background-color: $white;
        }
    }
}

.overview-card_tippy {
    .tippy-content {
        word-break: break-all;
    }
}
