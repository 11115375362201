@use '~theme/colors' as *;
@use '~theme/typography' as *;


#report-issue-modal {
    width: 800px;

    .modal-body {
        padding-top: 8px;
        padding-bottom: 8px;

        .input-wrapper {
            padding-bottom: 17px;

            .label {
                @include font-regular-text-md;
                padding-bottom: 3px;
                color: $dove-gray;
            }

            .text-area-container {
                margin: 0;

                .text-area-component {
                    margin: 0;
                    min-height: 160px;
                }
            }
        }
    }
}