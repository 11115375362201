@use '~theme/mixins' as *;

#asset-details-container {
	.header-container {
		padding: 20px 12px 24px 28px;

		> .row {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
}
