@use '~theme/colors' as *;
@use '~theme/mixins' as *;

.powertrainList_item {
    @include shadow-inset-alto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px 12px 8px;
    gap: 8px;

    &_label {
        overflow-wrap: anywhere;
        word-break: break-word;
        flex: 1;
    }

    &:hover {
        cursor: pointer;
    }

    &.selected {
        background-color: $gallery;
    }
}