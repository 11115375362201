@use '~theme/colors' as *;

.cross-asset-analysis-container .sidepanel-container {

	.toggle-button-container {
		min-height: 32px;

		.toggle-button {
			width: 124px;
			font-size: 14px;
			line-height: 16px;
		}
	}

	.search-field,
    .powertrain-filter {
		margin-top: 16px;
        width: 250px;
	}

	.gateway-type-filter {
		width: 250px;
		margin-top: 12px;
	}
}
