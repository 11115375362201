@use '~theme/colors' as *;
@use '~theme/typography'as *;

.asset-nameplate-image-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: inherit;
	height: 80px;

	.image-holder {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $wild-sand;
		cursor: pointer;
		min-width: 80px;
		width: 80px;
		height: 80px;
		border-radius: 5px;

		&:nth-of-type(1) {
			margin-right: 8px;
		}

		&:hover {
			box-shadow: 0px 0px 2px $tundora;
		}

		&:active {
			box-shadow: 0px 0px 5px $tundora;
		}

		img {
			width: 80px;
			height: 80px;
			border-radius: 5px;
		}
	}
}

#asset-nameplate-image-viewer-modal {
	margin: 0 auto;
	width: auto;
	.modal-content {
		margin: 0 auto;
		height: 650px;
		width: 750px;
	}
	.disabled-capability {
		pointer-events: none;
		opacity: 0.2;
	}

	.image-gallery {
		.fullscreen {
			.image-gallery-slides {
                div {
                    img {
                        height: 90vh;
                    }
                }
			}
		}

		.image-gallery-slide-wrapper {
			background-color: $alabaster;

			.image-gallery-slides {
				img {
					height: 400px;
					width: 98%;
				}

				.image-gallery-slide .image-gallery-description {
					background: $ebb-transparent;
					bottom: 0px;
					color: #2e2e2eed;
					border-radius: 0px 7px 0px 0px;
				}
			}
		}

		.image-gallery-thumbnails-wrapper {
            .image-gallery-thumbnails button {
                width: 150px;
            }
			.image-gallery-thumbnails button img {
				height: 75px;
                object-fit: cover;
			}
		}
	}

	.image-viewer-modal-no-image-default {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		background-color: $gallery;
		padding-top: 150px;
		height: 520px;

		h3 {
            @include font-medium-text-xl;
			margin-bottom: 40px;
            text-align: center;
		}
	}

    .asset-image-thumbnail-description {
        background: $ebb-transparent;
        color: #2e2e2eed;
        font-size: 12px;
        height: 32px;
    }
}
