@use '~theme/colors' as *;
@use '~theme/typography' as *;

.general-select-container.organization-selector-container {
	.general-select__menu {
		padding: 8px 8px 8px 0px;

		.general-select__option {
			padding: 0px 10px 0px 20px;
			background-color: transparent;

			.radio-button,
			.radio-button-disabled {
				padding: 0px;

				.radio-button-label {
					margin-left: 6px;
				}
				.radio-button-label-text{
					line-height:32px;
                    white-space: unset;
                    text-overflow: unset;
				}
			}

			&:hover{
				background-color: $pattens-blue;
			}
		}
	}
}
