@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;

.header-component {
	@include unselectable;
	height: 51px;
	@include shadow-inset-alto;
    position: relative;

	.navbar {
		padding-bottom: 0px;
		font-size: 16px;
		line-height: 25px;
	}

	.red-line {
		position: fixed;
		width: 30px;
		height: 4px;
		top: 12px;
		background: $red;
	}

	.pt-navbar-container {
		@include font-regular-text-lg;
		z-index: $pt-navbar-container-zIndex;
		border-radius: 0px;
		background-color: $white;
		padding-left: 16px;
		line-height: 24px;
        height: 50px;

		.navbar-brand {
			@include font-medium-subtitle;
			line-height: 24px;
            padding: 0;
		}

		.navbar-nav {
            cursor: pointer;

			.nav-link {
			border-bottom: 2px solid transparent;
			}
        }

		.navbar-nav .active {
			border-bottom: 2px solid $dodger-blue;
            pointer-events: none;
		}

		.nav-item {
			@include font-regular-text-lg;
			font-weight: 500;
			font-style: normal;
			text-decoration: none;
			color: $mine-shaft;
			width: inherit;
			height: inherit;
		}

		.pt-navbar-right {
			position: absolute;
			right: 10px;

			.user-dropdown-menu {
				border-bottom: 2px solid transparent;

				.dropdown-toggle {
					background-color: white;
					border-color: white;
					border-radius: 0;
					box-shadow: none;
				}

				&.show {
					border-bottom: 2px solid $dodger-blue;
				}
			}

			.dropdown-toggle::after {
				display: none;
			}

			.right-dropdown-menu {
				text-align: center;
				padding: 0px;

                .dropdown-toggle {
					background-color: white;
					border-color: white;
					border-radius: 0;
					box-shadow: none;
				}

				.dropdown-toggle::after {
					display: none;
				}

				.dropdown-menu {
					@include font-regular-text-md;
					width: 240px;
                    max-width: 240px;
					transform: none;
					margin: 0;
					top: 43px;
					right: -2px;
					left: auto;
					background-color: $white;
					animation: none;

					.dropdown-item {
                        border: none;
						padding-left: 16px;
                        white-space: normal;
					}

					.dropdown-item.active {
						background-color: $pattens-blue;
						color: $mine-shaft;
					}
					.dropdown-item:hover {
						background-color: $gallery ;
						color: $mine-shaft;
					}
				}
			}

			.user-dropdown-menu {
				text-align: center;
				padding: 0px;

				&.show {
					>a {
						border-bottom: 2px solid $dodger-blue;
					}
				}

				.dropdown-toggle::after {
					display: none;
				}

				.dropdown-menu {
					@include font-regular-text-md;
					min-width: 240px;
					transform: none;
					margin: 0;
					top: 43px;
					right: -2px;
					left: auto;
					background-color: $white;
					animation: none;

					.dropdown-item {
						padding-left: 16px;
					}

                    .dropdown-item.active {
						background-color: $pattens-blue;
                        border-bottom: none;
						color: $mine-shaft;
					}

					.dropdown-item:hover{
						background-color: $gallery;
						color: $mine-shaft;
					}

					.user-name-email {
						cursor: default;
						line-height: 16px;
						padding: 5px 16px 10px 16px;
						.user-name {
							@include font-bold-text-md;
						}
						.user-email {
							font-weight: 400;
							@include font-regular-text-sm;
						}
					}
				}
			}
		}
	}
}
