@use '~theme/colors' as *;
@use '~theme/typography' as *;

#limit_Offset {
    .modal-content {
        width: 480px;
        border-radius: 8px;

        .modal-header {
            .btn-close {
                font-size: 10px;
            }

            border-top: 0 !important;
        }

        .modal-body {
            .offset-info {
                display: flex;
                padding: 10px 12px;
                background: rgba(0, 81, 255, 0.08);
                border-radius: 8px;
                align-items: center;
                gap: 16px;
                font-family: 'ABBvoice-Regular', sans-serif;
                margin-bottom: 8px;

                p {
                    flex: 1;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                }

                svg {
                    path {
                        fill: '#0051FF';
                    }
                }
            }

            .offset-input {
                .offset-input-title {
                    margin-bottom: 8px;
                }

                .limit-value-uom {
                    display: flex;
                    margin-bottom: 16px;
                    align-items: center;
                    column-gap: 8px;
                    border-width: 2px;
                    border-color: $alto;
                    border-style: solid;
                    height: 40px;
                    width: 100%;
                    padding: 0 12px;
                    border-radius: 8px;

                    input {
                        flex: 1;
                        border: 0;
                        outline: 0;
                        font-size: 14px;
                        color: $dove-gray;
                        font-family: 'ABBvoice-Regular', sans-serif;
                    }

                    input[type=number]::-webkit-outer-spin-button,
                    input[type=number]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }


                    .limit-pipe {
                        color: $dove-gray;
                    }

                    .uom-text {
                        font-size: 14px;
                        color: $dove-gray;
                        font-family: 'ABBvoice-Regular', sans-serif;
                        min-width: 40px;
                        text-align: center;
                    }
                }

            }
        }

        .modal-footer {
            .dialog-accept-button {
                border-radius: 32px;
            }
        }
    }
}

.limit-config-container {

    .limit-btn {
        font-family: 'ABBvoice-Medium', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }

    .context-menu .context-menu-list .context-menu-list-item,
    .context-menu .context-menu-list .context-menu-list-item-disabled {
        height: unset;
    }

    .limit-config-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 8px 16px 32px;
        flex-wrap: wrap;
        row-gap: 10px;

        .header-left,
        .header-right {
            display: flex;
            align-items: center;
            column-gap: 8px;
        }

        .limit-search-box {
            border-radius: 4px;
            border: 2px solid $alto;
        }

        .limit-count {
            font-family: 'ABBvoice-Regular', sans-serif;
            color: $dove-gray;
        }

    }

    .signal-limit-container {
        .expandable-component {
            border-bottom: 1px solid $silver;

            .expandable-content {
                overflow: unset;
            }
        }

        .content-container {
            border-top: 1px solid $silver;

            .limit-values-container {
                position: relative;
                padding: 4px 0 4px 40px;
                display: flex;
                align-items: center;
                column-gap: 8px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .item-disable {
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    position: absolute;
                    z-index: 9;
                    cursor: not-allowed;
                }

                .limit-left-values {
                    flex: 1;
                    display: flex;
                    column-gap: 16px;
                    align-items: center;

                    .limit-value-fields {
                        display: flex;
                        align-items: center;

                        .general-select__control {
                            width: 80px;
                            border-width: 2px;
                            border-right-width: 0;
                            border-radius: 6px 0 0 6px;
                            border-color: $alto;
                        }

                        .limit-value-uom {
                            display: flex;
                            align-items: center;
                            column-gap: 8px;
                            border-width: 2px;
                            border-color: $alto;
                            border-style: solid;
                            height: 32px;
                            width: 320px;
                            padding: 0 12px;
                            border-radius: 0 6px 6px 0;

                            &.disabled {
                                background-color: $gallery;
                            }

                            input {
                                flex: 1;
                                border: 0;
                                outline: 0;
                                font-size: 14px;
                                color: $dove-gray;
                                font-family: 'ABBvoice-Regular', sans-serif;
                            }

                            input[type=number]::-webkit-outer-spin-button,
                            input[type=number]::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }


                            .limit-pipe {
                                color: $dove-gray;
                            }

                            .uom-text {
                                font-size: 14px;
                                color: $dove-gray;
                                font-family: 'ABBvoice-Regular', sans-serif;
                                min-width: 40px;
                                text-align: center;
                            }
                        }

                        .limit-value-uom.error {
                            border-color: $pomegranate;
                        }
                    }
                }
            }

            .limit-slider-container {
                padding: 50px 40px 8px 48px;
            }

            .limit-level-container {
                padding: 4px;
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 8px;

                .toggle-button-container {
                    column-gap: 8px;
                    color: $nero;
                    font-size: 14px;
                    font-weight: 500;

                    button {
                        border-radius: 4px;
                        background: transparent;
                        border: 0;
                        min-width: 70px;
                        padding: 4px !important;
                        font-weight: 500;
                        font-family: 'ABBvoice-Medium', sans-serif;
                    }

                    button.active {
                        background: rgba(0, 0, 0, 0.24);
                        color: $nero;
                        font-family: 'ABBvoice-Medium', sans-serif;
                        font-weight: 500;
                    }
                }
            }
        }

        .title-content {
            width: 100%;
        }

        .limit-type-container {

            .limit-type {
                padding: 4px 8px 4px 48px;

                .general-select__control {
                    border-radius: 4px;
                    max-width: 400px;
                }

                .general-select__menu {
                    max-width: 400px;
                }
            }

            .no-limits {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                font-family: 'ABBvoice-Regular', sans-serif;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                padding: 21px 0;
                color: $dove-gray;
            }
        }

        .limit-title-container {
            display: flex;
            align-items: center;
            flex: 1;

            .limit-title-left {
                flex: 1;
                display: flex;
                align-items: center;
                padding: 8px 16px 8px 24px;

                .limit-signal {

                    font-size: 16px;
                    font-weight: 500;
                    font-family: 'ABBvoice-Medium', sans-serif;
                    color: $nero;
                    display: flex;
                    text-align: left;

                    input {
                        width: 300px !important;
                    }

                    .limit-uom {
                        color: $dove-gray;
                        margin-left: 10px;
                    }

                    .limit-pipe {
                        margin: 0 5px 0 2px;
                        color: rgba(0, 0, 0, 0.12);
                    }
                }

                .limit-offset {
                    font-size: 16px;
                    font-weight: 400;
                    color: $nero;
                    font-family: 'ABBvoice-Regular', sans-serif;
                }
            }

            .limit-title-right,
            .limit-notify {
                display: flex;
                align-items: center;
            }

            .limit-title-right {
                padding: 8px 16px;
                column-gap: 8px;

                .general-button.discreet:hover {
                    border: 0;
                }

                .limit-discard {
                    background: transparent;
                }

                .limit-cancel,
                .limit-save {
                    font-family: 'ABBvoice-Medium', sans-serif;
                    font-weight: 500;
                }

                .limit-save {
                    margin-right: 8px;
                }

                .add-limit {
                    display: flex;
                    column-gap: 8px;
                    align-items: center;
                    font-weight: 500;
                    font-size: 14px;
                    color: $nero;
                }

                .add-limit-disabled {
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.40);
                }
            }

            .limit-notify {
                font-size: 14px;
                font-family: 'ABBvoice-Medium', sans-serif;
                font-weight: 500;
                color: $nero;

                span {
                    margin-left: 8px;
                }
            }
        }
    }
}