@use '~theme/colors'as *;
@use '~theme/mixins'as *;

.cross-asset-analysis-container .asset-list-container {
    .parameter-kpi-list-item {
        /* as per new design the box shadow commented */
        // @include shadow-inset-alto;
        display: flex;
        align-items: center;
        padding: 8px 3px 8px 12px;
        min-height: 40px;
        cursor: pointer;
        overflow-wrap: break-word;

        &:hover {
            background-color: $concrete;
        }
        &.selected {
            background-color: $light-blue;
        }
        .parameter-kpi-name {
            width: 80%;
            padding: 0px 8px;
        }

        .kpi-name-img-container {
            width: 20%;
            text-align: right;
            padding-right: 6px;
        }
    }

    .kpi-list .kpi-parameter-signals {
        padding: 8px 0px 8px 0px;
        width:200px;
    }

    .kpi-parameter-signals .general-select-container .general-select__menu .general-select__option {
        padding: 0px;
    }
}
