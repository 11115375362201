@use "~theme/colors" as *;
@use '~theme/typography'as *;
@use "~theme/breakpoints" as *;

#assistance-logger-signals{
	height: 47vh;
	@include md-min-h {
		height: 47vh;
	}
	@include lg-min-h {
		height: 53vh;
	}
	@include xl-min-h {
		height: 61vh;
	}
}

#assistance-logger-signals-pop-out {
	.highcharts-legend {
		.highcharts-legend-item span:first-of-type {
			padding-top: 2px;
		}
	}
}

#assistance-logger-visualisation-container {
	@include font-regular-text-md;
	margin-left: 0px;
	margin-right: 0px;
	line-height: 16px;

	.data-logger-selector-label,
	.trend-signal-selector-label {
		font-size: 14px;
		color: $dove-gray;
		margin-bottom: 3px;
	}

	.row.assistance-trend-chart {

        .popout-chart-row {
            padding: 0 0 0 0;
        }

		.centered-col {
			justify-content: center;
			align-items: center;
			display: flex;
		}

		.selection-info-message {
			margin-top: 10px;
			height: 230px;
		}
	}

	.highcharts-legend {
		.highcharts-legend-item span:first-of-type {
			padding-top: 2px;
		}
	}
	.highcharts-tooltip {
		span {
			.custom-trend-tooltip {
				.logger-name{
					@include font-bold-text-lg;
					font-size: 14px;
					font-weight: 700;
					font-style: normal;
				}

				.t-item {
					@include font-regular-text-md;
					font-weight: 400;
					line-height: 16px;
					font-style: normal;
				}

				.t-header {
					@include font-regular-text-md;
					line-height: 16px;
					font-style: normal;
					padding-bottom: 8px;
				}
			}
		}
	}
}
