@use '~theme/typography' as *;

.general-filter-asset-search {
    padding-bottom: 35px;

    .general-filter-asset-search-label {
        font-family: $font-family-bold;
    	font-size: 15px;
    }

    .general-filter-asset-search-input {
        .input-container {
            width: 100%;
            margin: 10px 0px;
        }
    }

    .general-filter-asset-search-button {
        float: right;
    }
}