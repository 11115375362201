@use '~theme/colors' as *;
@use '~theme/typography' as *;

.extra-loading-wrapper {
	height: 300%;
}

#bulk-status-section {
	.refresh-button {
		border-style: none;
		width: 32px;
		height: 32px;
		margin-left: 5px;
		border-radius: 4px;
		background-color: $alto;
		position: relative;

		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			-webkit-transform: translateX(-50%) translateY(-50%);
		}

		&:disabled,
		&[disabled] {
			background-color: $athens-gray;
		}
	}

	.general-pagination {
		margin-top: 24px;
	}

	.status-section-header {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 24px;

		.section-label {
			@include font-regular-text-md;
			line-height: 16px;
			margin-left: 24px;
			display: flex;
			align-items: center;
		}
	}
}
