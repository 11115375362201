@use "~theme/colors" as *;
@use "~theme/typography" as *;
@use "~theme/table" as *;
@use "~theme/breakpoints" as *;

.logger-download-container {
	.general-table {
		.rc-table-container {
			.rc-table-content {
				table {
                    border-collapse: separate;
                    border-spacing: 0;

                    th {
                        border-bottom: 1px solid $black;
                    }

                    td {
                        border-bottom: 1px solid $silver;
                    }

					.rc-table-thead {
						border-bottom: 1px solid $black;
						height: 32px;
						tr {
							.rc-table-cell:first-of-type {
								.general-table-title {
									margin-left: 15px;
								}
							}

							.rc-table-cell {

                                &.rc-table-cell-fix-left-last {
                                    width: 80%;
                                }

								.general-table-title {
									@include font-medium-text-sm;
									line-height: 14px;
									align-items: center;
									text-transform: uppercase;
									color: $dove-gray;
								}
							}
						}
					}
					.rc-table-tbody {
						.rc-table-row {
							height: 40px;
							border-bottom: 1px solid $silver;
							cursor: auto;
							.rc-table-cell:first-of-type {
								.file-name-cell {
									margin-left: 15px;
									.file-name-text {
										margin-left: 18px;
									}
								}
							}

							.rc-table-cell {
								@include font-regular-text-md;
								line-height: 20px;
								align-items: center;
								color: $mine-shaft;
								.download-logger {
									.download-icon {
										width: 14px;
										cursor: pointer;
									}
								}
							}
						}

						.logger-file-name {
							width: 80%;
						}
						.logger-time {
							width: 17%;
						}
						.logger-dowload-icon {
							width: 3%;
						}
						@include xl-max-w {
							.logger-file-name {
								width: 60%;
							}
							.logger-time {
								width: 30%;
							}
							.logger-dowload-icon {
								width: 10%;
							}
						}
					}
				}
			}
		}
	}

	.rc-table-ping-left {
        .rc-table-cell-fix-left {
			@include fixed-column;
        }
    }
}
