@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use "~theme/breakpoints" as *;

#alert-alarm-offset-modal {
    width: 640px;

    .modal-header {
        padding: 10px 0;
    }

    .modal-content {
        min-height: 600px;
        display: flex;
        .container {
            padding: 0px;
            .toggle-button-row {
                margin-bottom: 24px;
            }
            .expandable-component {
                .header {
                    @include shadow-inset-alto;
                    background-color: $wild-sand;
                    padding: 8px;
                    height: 40px;
                    width: 574px;

                    .open-close-container {
                        margin-left: 0px;
                        margin-right: 10px;
                    }

                    .title-content {
                        width: 100%;
                    }
                }

				.expandable-content {
					overflow: visible;
				}

                .alert-alarm-dialog-row {
                    margin: 18px 0 15px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .notification-switch {
                        display: flex;
                        align-items: center;
                        height: 60px;
                        span {
                            margin-left: 10px;
                        }
                    }

                    .alert-alarm-input-group-wrapper {
                        height: 60px;
                        .alert-alarm-info {
                            height: 70px;
                        }
                        .error-container > svg {
                            display: none;
                        }
                    }

                    .alert-alarm-icon {
                        color: $orange-peel;
                        font-size: 32px;
                        float: left;
                        position: relative;
                        top: 40%;
                        line-height: 20px;
                    }
                    .alert-alarm-input-wrapper {

                        .alert-alarm-input {
                            .general-input-container {
                                width: 200px;
                                .input-container{
                                    width:100%
                                }
                            }
                            margin: 0 18px;
                            flex-direction: column;
                        }

                        .alert-alarm-info {
                            height: 35px;
                        }
                    }
                    .suggest-button,
                    .save-button,
                    .reset-to-defaults-button,
                    .calculate-button {
                        margin-left: 25px;

                    }
                    .reset-to-defaults-button {
                        margin-bottom: 2px;
                    }
                    .suggest-button {
                        margin-right: 8px;
                    }

                    .select-time-interval {
                        width: 260px;
                    }
                    .aa-input-label {
                        color: $dove-gray;
                        line-height: 16px;
                        margin-bottom: 4px;
                    }

                    &.suggest {
                        display: block;

                        .calculate-reset-buttons {
                            margin: 15px -25px;
                        }
                    }
                }
            }

            .alert-alarm-info {

                float: right;
                .icon_info {
                    margin-right: 5px;
                    cursor: pointer;
                }
                .tippy-popper {
                    text-align: left;
                }
                .tippy-content {
                    font-size: 11px;
                    max-width: 370px;
                    @include customScreenMaxWidth(550) {
                        width: 250px;
                    }
                }
            }

            .hidden-tab {
                visibility: hidden;
                height: 0px;
            }
        }


        .modal-body{
            overflow: auto;
        }
    }
}
