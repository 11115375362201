@use "~theme/colors" as *;

#operational-parameters-container .operational-parameter-tab-container #trend-container
.multi-graph-view {
    display: flex;
    flex-wrap: wrap;

    &.hidden {
        display: none;
    }

    .chart-wrapper-row {
        min-height: 272px;
    }

    .multi-graph-item {
        width: 480px;
        height: 320px;
        padding: 5px 5px;
        margin: 15px 15px 0px 0px;;
        border: 1px solid $alto;
        border-radius: 4px;

        #popout-chart-row-button-container {
            padding-top: 0px;
            min-height: 40px;
        }

        .chart-title {
            color: $tundora;
            text-align: center;
            font-size: 16px;
            font-family: 'ABBvoice-Regular';
            font-weight: 500;
        }

        .popout-chart-row:first-of-type {
            padding: 0px;
        }

        .popout-chart-row:last-of-type {
            height: 210px;

            .chart-container {
                .multi {
                    height: 210px;
                    
                    .highcharts-container {
                        height: 210px;
                    }
                }
            }
        }
        
    }
}
