@use '~theme/colors'as *;
@use "~theme/typography"as *;
@use '~theme/mixins' as *;

.step2 {
    .asset-sections{
        padding-top: 24px;
    }

    .section-title{
        padding-top: 20px;
    }

    .filter-row{
        padding-top:16px;
        .filter-control{
            width: 200px;
            .search-field{
                width: 200px;
                min-width: 200px;
            }
        }
    }

    .asset-table-row{
        padding-top: 25px;
        .loading-wrapper {
            height: 374px;
            width: 440px;
            margin-top: 70px;
            margin-left: 34px;
        }
    }

    .available-assets-section, .selected-assets-section{
        height: 374px;
        width: 440px;
        @include create-border($alto);
    }

    .available-assets-section{
        margin-left: 12px;
        margin-right: 16px;

        .loading-wrapper {
            margin: 0;
            height: 229px;
        }
    }

    .asset-item{
        .action span{
            cursor: pointer;
        }
    }
}