@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.tools_powertrain-configuration {
    @include standard-tabContent-layout;

    &_sidePanel {
        &_addNewPowertrain-button {
            text-align: left;
            width: 100%;
            display: flex;
            margin-bottom: 16px;
        }
    };
    .scrollable-list {
        margin-top: 16px;
    }

    .selection-info-message {
        width: 100%;
        height: 80px;
    };
}
