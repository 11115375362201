@use '~theme/typography'as *;
@use '~theme/_colors'as *;


.user-profile-settings-content {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .units {
        padding: 24px;
        display: grid;
        grid-template-rows: 32px;
        grid-template-columns: 520px;
        grid-row-gap: 16px;

        .unit {
            display: flex;
            flex-direction: row;
            align-items: center;


            &__label {
                @include font-regular-text-md;
                line-height: 32px;
                flex-basis: 50%;
            }

            &__toggle {
                flex-basis: 50%;


                &--all {
                    flex-basis: 50%;
                    @include font-regular-text-md;
                    cursor: pointer;
                    text-align: center;

                    &--left {
                        display: inline-block;
                        padding: 0 6px;
                        color: $dodger-blue;
                        text-align-last: center;
                        position: relative;
                        width: 50%;

                        &::after {
                            content: "|";
                            position: absolute;
                            color: $silver;
                            right: -2px;

                        }
                    }

                    &--right {
                        display: inline-block;
                        padding: 0 6px;
                        text-align: center;
                        color: $dodger-blue;
                        width: 50%;
                    }

                }
            }


            .toggle-button-container {
                width: unset;

                >.toggle-button {
                    flex: 1;
                }
            }

        }
    }
}