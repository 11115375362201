@use "~theme/colors" as *;

#scatter-container{
    #op-scatter-chart,
    #op-scatter-chart-pop-out {
        .scatter-tooltip {
            font-size: 14px;
            line-height: 20px;

            .scatter-tooltip-header {
                font-weight: bold;
                line-height: 16px;
                padding-bottom: 8px;
                margin-bottom: 8px;
                border-bottom: $alto solid 1px;
            }
        }
    }
}