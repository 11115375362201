.eula-modal-container {
    &.general-dialog-container.modal {
        z-index: 9005;

        .modal-content {
            width: 650px;
        }

        .general-dialog {
            width: 650px;
        }
    }
}