@use "~theme/colors" as *;
@use "~theme/typography" as *;

.asset-labels-container {
	display: flex;
	height: 33px;

	.add-label-button-wrapper {
		display: flex;
		align-items: center;
		border-radius: 4px;
		min-width: 185px;
		height: 32px;
		padding: 1px 0px 1px 0;

		.add-label-button {
			margin-top: auto;
			margin-bottom: auto;
            cursor: pointer;
		}

		.horizontal-scroll-container {
			.horizontal-arrow-next, .horizontal-arrow-prev {
				margin-top: -15px;
			}
		}
	}

	.label-list {
		position: relative;
		width: 53vw;
		min-width: 200px;
		margin-right: 10px;
		display: flex;
		align-items: center;
        ul {
            margin-bottom: 0;
        }

		.asset-label {
			@include font-regular-text-sm;
			display: flex;
			background-color: lightgray;
			border-radius: 12px;
			padding: 0px 7px;
			margin-right: 10px;
			height: 24px;
			align-items: center;
			white-space: nowrap;

			svg:first-of-type {
				transform: rotate(45deg);
				cursor: pointer;
			}
		}
	}

	.label-select {
		height: 22px;
		width: 185px;
		top: -10px;

		div:first-of-type {
			border: none;
			box-shadow: none;

			&:hover,
			&:focus {
				border: none;
				box-shadow: none;
			}

			div:nth-of-type(2) {
				border: none;

				> span {
					width: 0;
					border: none;

					&:hover,
					&:focus {
						width: 0;
						border: none;
					}
				}
			}

			div:first-of-type {
				padding: 0px;
			}
		}

		div:nth-of-type(2) {
			div:first-of-type {
				div {
					padding: 3px 5px;
					overflow-wrap: break-word;
				}
			}
		}
	}
}
