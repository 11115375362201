@use '~theme/mixins' as *;


.powertrains-overview{
    padding-right:14px;
    &__grid{
        @include card-grid;
    }

    .overview-card{
        cursor: pointer;
    }
}
