@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.powertrain-details {
    @include create-border;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;

    &_header {
        background-color: $wild-sand;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        gap: 8px;

        &_labels {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &_name {
                @include font-bold-subtitle;
                overflow-wrap: anywhere;
            }

            &_site {
                @include font-bold-text-lg;
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        &_buttons {
            padding-right: 4px;
            display: flex;
            justify-content: space-between;
            gap: 22px;
            align-items: flex-start;
            svg {
                cursor: pointer;
            }
        }
    }

    &_body {
        height: 100%;
        overflow-y: auto;
        padding: 16px;
        &_selector {
            padding-bottom: 16px;
            max-width: 200px;
        }
    }
}