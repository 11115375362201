@use "~theme/colors" as *;
@use "~theme/breakpoints" as *;
@use "~theme/mixins" as *;

#operational-parameters-container {
    padding: 12px 12px 0 12px;
    height: 100%;

    .operational-parameter-tab-chart-row {
        height: calc(100% - 52px);

        @include customScreenMaxWidth(1275){
            height: calc(100% - 104px);
        }
    }

    .operational-parameter-tab-container {
        // position: relative; // loading indicator will be positioned relative to this element
        @include standard-tab-container-spacing;
        padding-bottom: 10px;

        > div {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .table-empty-content {
            height: 250px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin: 20px 0;
        }

        .general-select-container {
            width: 350px;
        }

        .popout-chart-row {
            padding: 0 0 10px 0;
            margin: 0;
        }

        #trend-container, #scatter-container, #histogram-container, #vibration-fft-container {
            #popout-chart-row-button-container{
                min-height: 50px;
                padding-top: 10px;
            }

            .chart-wrapper-row {
                min-height: 290px;
            }

            .popout-chart-row:last-of-type{
                padding: 0px;

                @include md-min-h {
                    height: 89%;
                }

                @include lg-min-h {
                    height: 90%;
                }

                @include xl-min-h {
                    height: 91%;
                }

                .col{
                    height: 100%;

                    .chart-container{
                        height: 100%;

                        #op-scatter-chart, #op-histogram-chart ,#op-fft-chart {
                            height: 100%;
                        }

                        .selection-info-message,
                        .highcharts-no-data,
                        #op-trend-chart{
                            @include md-min-h {
                                height: 300px;
                            }

                            @include lg-min-h {
                                height: 300px;
                            }

                            @include xl-min-h {
                                height: 300px;
                            }
                        }
                    }
                }
            }
        }
    }
}
