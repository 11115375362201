@use '~theme/colors' as *;
@use '~theme/typography' as *;

#rename-modal {
	.modal-footer {
		padding: 32px 10px 17px;
	}

	.rename-modal-container {
		height: 62px;

		#renameComponent {
			width: 100%;
			height: 32px;
			margin-top: 10px;
		}

		.input,
		.input .input-container,
		.input .input-container input {
			width: 100%;
		}

		.asset-name-label {
			@include font-regular-text-md;
			color: $dove-gray;
			padding-left: 2px;
			line-height: 16px;
		}
	}

	.form-control {
		text-align-last: left;
	}
}
