@use "~theme/breakpoints" as *;

#operational-parameters-container{
        .tab-actions-container {
        min-height: 32px;
        padding-right: 24px;
        padding-left: 12px;

        > div {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 0px;

            &:first-child {
                min-width: 370px;

                @include customScreenMaxWidth(1275) {
                    flex-basis: 100%;
                }
            }

            &:last-child {
                @include customScreenMaxWidth(1275) {
                    flex-grow: 1;
                }
            }
        }
        .toggle-buttons {
            display: flex;
            column-gap: 50px;
        }
    }
}