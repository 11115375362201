@use '~theme/colors' as *;
@use '~theme/mixins' as *;

.parameter-backup-modal.modal {
	.general-dialog {
		position: relative;
		width: 640px;

		.modal-header {
			padding: 10px 25px;
		}

		.modal-content {
			height: 630px;
		}
	}

	.parameter-backup-form {
		.time-selector-row .time-selector-row-content {
			justify-content: flex-start;
			padding: 0px 0px 25px 0px;
		}
		.parameter-table {
			.parameter-backup-download-button {
				width: 16px;
				border-radius: 4px;

				&:hover {
					background-color: $gallery;
				}

				&:active {
					background-color: $mercury;
				}
			}
		}
	}
}
