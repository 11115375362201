@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use "~theme/typography" as *;
@use "~theme/breakpoints" as *;

#assistance-trend-container .measurement-list-container {
    
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin: 0px;

    .measurement-list {
        @include create-border;
        width: 50%;
        height: 100%;
        max-height: 250px;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 16px;
        margin: 6px 0;

        .measurement-list-title {
            @include font-medium-text-lg;
            color: $nero;
            line-height: 24px;
            padding-left: 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid $nero;
            display: flex;
            justify-content: space-between;
            
            svg {
                margin: 0 10px 0 10px;
                cursor: pointer;
            }
        }
        .measurment-list-buttons {
            display: flex;
        }
        .signal-group-header-info {
            color: $dove-gray;
            font-size: 13px;
        }

        .measurement-list-body {
            overflow: auto;
            max-height: 242px;
        }

        .measurement-list-item {
            @include font-regular-text-md;
            @include shadow-inset-alto;
            display: flex;
            align-items: center;
            padding: 12px 8px;
            line-height: 18px;
        
            &:hover {
                background-color: $concrete;
                cursor: pointer;
            }
            &.selected-measurement-list-item {
                background-color: $light-blue;
            }
            
            .data-command-checkbox{
                width: 8%;
                svg{
                    padding: 0px,16px,0px,16px;
                }
            }
            .data-label {
                width: 45%;

                svg {
                    margin: 0px 8px 0px 8px;
                }
            }

            .data-value {
                overflow-wrap: break-word;
                width: 25%;
            }

            .data-command {
                text-align: right;
                width: 100%;
                >span {
                    margin: 0 10px 0 10px;
                }

                svg {
                    cursor: pointer;
                }
            }
        }
    }

    @include customScreenMaxWidth(1440) {
        width: 100%;

        .measurement-list {
            width: 100%;
            height: initial;
        }
    }
}