@use '~theme/_colors' as *;

.profile-settings {
    position: relative;
    padding: 25px;
    min-height: 250px;

    .profile-item-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        &:last-child {
            padding-bottom: 0;
        }

        .icon {
            padding-right: 16px;
        }

        .value-wrapper {
            display: flex;
            flex-direction: column;

            .item {
                color: $dove-gray;
                padding-bottom: 6px;
            }

            .general-select-container {
                width: 240px;
            }

            #language_dropdown {
                .general-select__option--is-disabled {
                    cursor: default;
                    border-top: solid 1px $silver;
                    margin-top: 10px;
                }
            }
        }
    }
}
