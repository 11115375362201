@use '~theme/typography'as *;
@use '~theme/zIndexes' as *;
@use '~theme/breakpoints' as *;

.bulk-upload-dialog {
	@include font-medium-text-md;
	min-width: 90vw;
	@include xl-min-w {
		min-width: 960px;
	}

	.modal-content {
		min-height: 600px;

		.note-buttons-container {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 24px;

			.buttons-row {
				display: flex;

				.general-button {
					margin-left: 8px;
					padding: 0px 8px;
				}
			}

			.bulk-upload-note {
				@include font-regular-text-md;
				line-height: 16px;
			}
		}

        .modal-body {
            table {
                border-collapse: separate;
                border-spacing: 0;
            }
        }
	}
}

.dialog-second-level.modal {
	z-index: $dialog-second-level-zIndex;

	.general-dialog {
		margin: 14rem auto;
	}
}

.dialog-second-level-backdrop.modal-backdrop {
	z-index: $dialog-second-level-zIndex;
}
