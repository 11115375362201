@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use '~theme/zIndexes' as *;

#powertrain-assets-overview-condition-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 16px 16px 0px;

    .condition-based-filter-row {
        padding: 24px 20px 24px 0px;
        position: relative;

        .condition-based-filter {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            .toggle-button-container {
                display: grid;
                grid-template-columns: minmax(96px, 1fr) minmax(96px, 1fr);
            }

            .condition-statuses {
                display: flex;
                align-items: center;
                margin-left: 8px;

                .condition-status-wrapper {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 25px;
                    }

                    .condition-status {
                        @include create-border($silver);
                        cursor: pointer;
                        padding: 1px;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 6px;

                        &.selected {
                            @include create-border($dodger-blue);
                            background-color: $pattens-blue;
                        }
                    }

                    .asset-number {
                        padding-left: 6px;
                    }
                }
            }

            .general-select-container.asset-type-filter {
                width: 200px;
                margin-right: auto;
            }
            >.info-button-with-online-help{
                display: flex;
                align-items: center;
                margin-left: -10px;
                svg.icon_info{
                    margin-bottom: 0px;
                }
            }
        }

            .search-field {
                width: 230px;
                min-width: 200px;
                height: unset;
                margin: unset;
            }

        &::after{
            position: absolute;
            content: "";
            bottom: 0;
            right: -30px;
            width: calc(100% + 60px);
            border-bottom: 1px solid $alto;
        }
    }

    >.powertrain-assets-overview-container{
        height: 100%;
        overflow: auto;
        scrollbar-gutter: stable;
        margin-top: 16px;

        .selection-info-message > span{
            max-width:605px;
        }
    }

    

}
