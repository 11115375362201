@use '~theme/colors' as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;
@use "~theme/breakpoints" as *;

#sensor-properties-container {
    padding: 12px;
    position: relative;
    height: 100%;

    .sensor-properties-not-available-component {
        @include unselectable;

        .sensor-properties-not-available-img-text {
            margin-top: 161px;

			.data-icon {
                width: 50px;
                margin: 0 auto;
            }

            .not-available-text {
                @include font-regular-text-lg;
                text-align: center;
				line-height: 19px;
                margin: 22px 0px 30px 0px;
            }
        }
    }

    .sensor-properties-component {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: initial;

        .sensor-properties-lists-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;

            .sensor-properties-list {
                @include create-border;
                display: flex;
                flex-direction: column;
                background-color: $white;
                padding: 16px;
                margin: 12px;

                .sensor-properties-list-title {
                    @include unselectable;
                    @include font-medium-text-lg;
                    color: $nero;
                    line-height: 24px;
                    padding-left: 8px;
                    padding-bottom: 16px;
                    border-bottom: 1px solid $nero;
                }

                .sensor-properties-list-item {
                    @include font-regular-text-md;
                    @include shadow-inset-alto;
                    display: flex;
                    padding: 12px 8px;
                    line-height: 16px;

                    .data-label {
                        width: 50%;
                    }

                    .data-value {
                        width: 50%;
                        text-align: right;
                        overflow-wrap: break-word;

                        .notification-type-RSSI,
                        .notification-type-degraded-mode {
                            @include unselectable;
                            float: right;
                            .icon_info {
                                margin-right: 5px;
                                cursor: pointer;
                            }
                            .tippy-popper {
                                text-align: left;
                            }
                        }

						.error-message-firmware-update {
                            @include unselectable;
							display: flex;
							float: right;

							.icon_info {
								cursor: pointer;
								margin-bottom: 3px;
							}

							.error-message-text {
								color: $pomegranate;
								margin-left: 5px;
								font-size: 12px;
								min-width: 220px;
							}

							.tippy-popper {
								text-align: left;
							}
						}
                    }
                }
            }
        }
        @include customScreenMaxWidth(1440) {
            flex-direction: column;
        }
    }
}