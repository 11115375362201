@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;

.login-powertrain {

	.login-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		background-color: $alto-transparent;

			.login-card {
				background-color: $white;
				width: 960px;
				height: 460px;
				padding: 32px 40px;

				.login-header {
					display: flex;
					justify-content: end;
					width: 100%;
					border-bottom: 1px solid $silver;
					padding: 10px 0px;
				}

				.not-production-warning {
					text-align: center;
				}

				.login-body {
					margin: 40px 20px 0px 20px;
					line-height: 28px;
					height: calc(100% - 145px);
					display: flex;
					flex-direction: column;

					.login-body_app-name-text {

						div:last-of-type {
							@include font-regular-text-lg;
							color: $dove-gray;
							margin-top: 16px;
						}
					}

					.login-body_login-button {
						width: 120px;
						height: 32px;
						margin-top: 25px;
					}
				}
			}
	}

}
