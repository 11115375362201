@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use "~theme/table" as *;

.tools_powertrain-gateway_management {
    @include general-table;
    padding: 21px 24px 32px 0px;
    height: 100%;
    overflow-y: scroll;

    .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        .title-box{
            padding-bottom: 8px;
            border-bottom:3px solid #3366ff;
            width:fit-content;
        }
    }

    .disclaimer-container {
        margin-top: 16px;
        div{
            margin-bottom: 16px;
        }
    }

    .info-icon-wrapper {
        margin-top: -3px;
    }

    .day-of-week-container {
        margin-bottom: 16px;

        .label-title {
            display: block;
            margin-bottom: 8px;
            font-weight: 500;
        }

        .label-box {
            display: flex;
            margin-left: 8px;
            .day {
                padding: 4px 20px 4px 20px;
                border-radius: 4px;
                border: 1px solid lightgray;
            }
    
            .unselected {
                -tw-bg-opacity: 1;
                background-color: rgb(59 130 246 / var(--tw-bg-opacity));
               }
    
            .selected {
                background-color: rgba(59, 130, 246, 1);
                color: rgba(255, 255, 255, 1);
              }
        }
    }

    .timelabel {
        display: flex;
        margin-right: 16px;
        margin-bottom: 16px;
    }

    .buttons {
        display: flex;
        margin-left: 8px;
    }

    .reset {
        padding: 8px 16px 8px 16px;
        background-color: rgba(229, 231, 235, 1);
        border-radius: 4px;
        color: rgb(55, 65, 81);
        border: 1px solid lightgray;
        margin-right: 16px;

    }

    .save {
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
        background-color: rgba(59, 130, 246, 1);
        color: rgba(255, 255, 255, 1);
        border: 0px;
}
}
