@use "~theme/colors" as *;
@use "~theme/typography" as *;


.edit-muted-asset-popup {
    .general-dialog {
        .modal-content {
            min-height: 200px;
        }
    }
}