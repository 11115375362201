@use '~theme/mixins' as *;
#event-log-tab-container {
    /* to solve the z-index issue bug 298089 */
    // position: relative;
    @include standard-tab-container-spacing;
    .event-log-content {
        margin-top: 15px;
        margin-bottom: 24px;
    }
}

