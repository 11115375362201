@use '~theme/colors' as *;
@use '~theme/typography' as *;

.pt-detailed-information-container .detailed-information-content {
	.detailed-information-header {
		background-color: $wild-sand;
		padding: 15px 15px 20px 15px;
		.row {
			align-items: center;
			.asset-name {
				@include font-bold-subtitle;
				line-height: 24px;
				font-weight: bold;
				overflow-wrap: anywhere;
			}
			.kebab-menu {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.pt-asset-details-tab.pt-tabs {
		background-color: $wild-sand;
		border-bottom: none;
		.nav-item,
		.nav-link {
			background-color: $wild-sand;
			&.active {
				border-top: 2px solid $nero;
				background-color: $white;
			}
		}
	}
}

.export-measurement-description {
	@include font-regular-text-md;
}
