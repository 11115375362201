@use '~theme/typography'as *;

.load-measurement-dialog{
    min-width: 640px;

    .load-measurement-container{
        @include font-regular-text-md;

        .info-text-row{
            padding-bottom: 15px;
        }

        .request-status-row{
            padding-top: 15px;

            .col{
                .request-status-text{
                    @include font-bold-text-md
                }
            }
        }

        .error-reason-row{
            padding-top: 15px;

            .col{
                .error-reason-text{
                    @include font-bold-text-md
                }
            }
        }
    }
}
