@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

#asset-details-container {
	padding: 12px;

	.asset-details-component {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
		flex-wrap: initial;

		.asset-detail-lists-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 50%;

			@include customScreenMaxWidth(1440) {
				width: 100%;
			}

			.asset-detail-list-container {
				width: 100%;
				.asset-detail-list {
					@include create-border;
					display: flex;
					flex-direction: column;
					background-color: $white;
					padding: 16px;
					margin: 12px;

					.asset-detail-list-title {
						@include font-medium-text-lg;
						color: $nero;
						line-height: 24px;
						padding-left: 8px;
						padding-bottom: 16px;
						border-bottom: 1px solid $nero;
					}

					.asset-detail-list-item {
						@include font-regular-text-md;
						@include shadow-inset-alto;
						display: flex;
						align-items: center;
						padding: 12px 8px;
						line-height: 16px;

						.data-label {
							width: 50%;
						}

						.data-value {
							@include font-regular-text-md;
							line-height: 16px;
							width: 50%;
							text-align: right;
							overflow-wrap: break-word;
							margin-bottom: -1px;
							overflow: unset;
							white-space: pre-wrap;
						}
					}
				}
			}
		}

		@include customScreenMaxWidth(1440) {
			flex-direction: column;
		}
	}
}
