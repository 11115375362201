@use '~theme/mixins' as *;

.powertrain-components_item {
    @include shadow-inset-alto;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding: 12px 0px 12px 8px;
    &_label {
        overflow-wrap: anywhere;
        flex: 1;
    }
}