@use '~theme/colors' as *;

.pt-overview-component {
	height: 100%;
	width: 100%;
	background-color: $wild-sand;

	.container-fluid {
		padding-right: 0px;
		padding-left: 0px;
	}

	.pt-overview-content {
		height: calc(var(--vh) * 100 - 51px);
		background-color: $white;

		.pt-overview-children {
			width: 100%;
			background-color: $wild-sand;

			.general-page-content {
				padding-top: 20px;

				> .tab-container > .tab-line,
				> .tab-container > .tab-content {
					padding-left: 32px;
				}
			}
		}

		.pt-tabs {
			padding-left: 32px;

			.nav-item,
			.nav-link {
				font-size: 14px;
				line-height: 14px;
				width: inherit;
				background-color: $alto;
				margin-right: 8px;
				border-radius: 0;

				&.active {
					border-top: 2px solid $dodger-blue;
					background-color: $white;
				}
			}
		}

		.tab-content {
			height: calc(var(--vh) * 100 - 145px);
			background-color: $white;
			overflow: clip;
		}
	}
}
