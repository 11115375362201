@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.user-profile-settings-wrapper {
    background-color: $white;
    height: calc(var(--vh) * 100 - 50px);

    .user-profile-settings-container {
        @include standard-tabContent-layout;
        padding: 24px 16px 24px 32px;

        .user-settings-sidepanel-container {
            align-items: flex-start;
            height: fit-content;

            .user-settings-label {
                @include shadow-inset-alto;
                @include font-bold-subtitle;
                padding-bottom: 16px;
                width: 100%;
                line-height: 24px;
            }
            .user-setting {
                @include shadow-inset-alto;
                @include font-regular-text-md;
                display: flex;
                align-items: center;
                width: 100%;
                cursor: pointer;
                height: 40px;
                color: $black;
                padding: 0;


                &.selected {
                    background-color: $gallery;
                }

                &:last-child {
                    box-shadow: none;
                }
            }

            svg {
                margin: 0px 9px 0px 12px;
            }
        }

        .user-profile-settings-content {
            @include font-medium-text-md;
            @include create-border;
            background-color: $white;
            width: 100%;
            height:fit-content;
            max-height: 100%;
            overflow-y: auto;

            &.component_profile{
                overflow-y: visible
            }

            .user-profile-settings-content-header{
                @include font-bold-subtitle;
                padding: 16px;
                background-color: $wild-sand;
                color: $mine-shaft;
                border-bottom: 1px solid $alto;
                line-height: 24px;
            }
        }
    }
}
