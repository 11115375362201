@use '~theme/mixins' as *;
@use '~theme/zIndexes' as *;

.portal-component {
    .notification-section {
        @include unselectable;
        position: absolute;
        z-index: $notification-zIndex;
        top: 15px;
        right: 15px;
    }
}

.minimum-res-warning-modal.modal, .minimum-res-warning-modal-backdrop.modal-backdrop {
    z-index: $min-res-modal-zIndex;
}
