@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;

.asset-table-row{
    .table-header{
        .asset-name-col{
            min-width: 320px;
            padding-left: 52px;
        }

        .asset-name-col, .action-col{
            @include font-medium-text-sm;
            text-transform: uppercase;
            color: $dove-gray;
            padding-bottom: 8px;
        }
    }

    .table-content{
        max-height: 228px;
        overflow-x: hidden;
        display: list-item;
        flex-direction: column;

        .asset-item{
            border-bottom: 1px solid $silver;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            overflow-wrap: break-word;
            padding: 10px;
            height: unset;

            &:hover {
                background-color: $concrete;
            }

            .asset-item-name{
                min-width: 320px;
                @include font-regular-text-md;
                display:flex;
                align-items: center;
                gap: 8px;

                &-label{
                    overflow-wrap: anywhere;
                    flex: 1;
                }
            }
        }

        &.asset-overview-table{
            max-height: 340px;
        }

        .no-asset-found {
            text-align: center;
            padding: 8px;
        }

        .no-asset-found {
            text-align: center;
            padding: 8px;
        }

        .no-asset-found {
            text-align: center;
            padding: 8px;
        }
    }
}