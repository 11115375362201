@use '~theme/typography'as *;
@use '~theme/_colors'as *;


.muted-assets-container {
    margin: 16px;

    .muted-assets-description {
        @include font-regular-text-md;
        color: $dove-gray;
    }

    .muted-assets-table-container {
        .asset-name-with-checkbox,
        .muted-assets-name-column {
            display: flex;

            .custom-checkbox {
                margin-right: 5px;
                margin-top: -2px;
            }
        }

        .general-table-title {
            .muted-assets-name-column {
                .custom-checkbox {
                    .checkbox {
                        margin-left: 0px;
                    }
                }
            }
        }
    
        .action-button-section {
            float: right;
            @include font-bold-text-md;
            margin-right: 8px;

            button {
                border: none;
                background-color: transparent;
                padding: 0px;

                svg {
                    margin-right: 20px;
                    margin-top: -2px;
                }
            }
        }
    }

    .rc-table-content {
        table {
            width: 100%;

            .general-table-title {
                cursor: default;
            }
        }
    }

    .muted-assets-bulk-operations {
        height: 45px;
        display: flex;
        justify-content: end;

        .assets-selected {
            @include font-regular-text-md;
            margin-top: 5px;
            color: $dove-gray;
        }

        .general-button {
            margin-left: 15px;
        }
    }
}

.edit-muted-asset-popup {
    .general-dialog-title {
        svg {
            margin-top: 8px;
        }
    }
}
