@use "~theme/colors" as *;
@use "~theme/typography" as *;

#assistance-trend-container {
    /* commented to fix the z-index issue */
    //position: relative;

    .popout-chart-row {
        padding: 0 0 5px 0;
    }

    .no-kpi-selected {
        @include font-regular-text-md;
        padding: 20px;
        width: 100%;
        height: 310px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $wild-sand;
        color: $dove-gray;
        flex-direction: column;

        > span {
            max-width: 600px;
            text-align: center;
        }
    }
}
