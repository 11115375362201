@use "~theme/colors"as *;

.highcharts-tooltip {
	span {
		font-size: 14px;

		.custom-fft-tooltip {
			padding: 15px;
			background-color: $white;
			border-width: 1px;
			border-style: solid;
			border-color: $mercury;
			box-shadow: 1px 1px $alto;

			b {
				color: $tundora;
			}

			.t-item {
				padding-top: 2px;

				span {
                    &.symbol {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                    }
				}
			}

			.t-header {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 10px;
				border-bottom: $alto solid 1px;
			}
		}
	}
}